import { CustomerInfo } from '../../build-info';
import { APICONFIG_HEADER_LANGUAGE } from '../_common';
import Axios from 'axios';

export enum HttpStatus {
    OK = 200,
    CREATED = 201,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    NOTFOUND = 404,
    UNPROCESSABLE_ENTITY = 422,
    INTERNAL_SERVER = 500,
    BAD_GATEWAY = 502
}

Axios.interceptors.request.use(
    (config) => {
        // do something with the response data
        config.auth = {
            username: 'omnicasa',
            password: CustomerInfo.ApiV2Token as string
        };
        config.headers[APICONFIG_HEADER_LANGUAGE] = CustomerInfo.defaultLanguage!.substring(0, 2).toLocaleUpperCase();
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

Axios.interceptors.response.use(
    function (response) {
        if (response.status === HttpStatus.OK || response.status === HttpStatus.CREATED) {
            return response.data;
        }
        return response;
    },
    function (error) {
        if (Axios.isCancel(error)) {
            console.warn('Axios request is cancelled');
        } else {
            console.warn('Axios unknown error');
        }
        return Promise.reject(error);
    }
);

export default Axios;
