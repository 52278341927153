import config from './config';
import { AxiosPromise, CancelToken } from 'axios';
import { IGeoAddress, IGeoAutocomplete } from '../_model/generic.model';
import { APICONFIG_HEADER_LANGUAGE } from '../_common';
import { CustomerInfo } from '../../build-info';
import Axios from './axios.config';

export const geolocationService = {
    getAddress,
    getAutocomplete
};

Axios.interceptors.request.use(
    (config) => {
        // do something with the response data
        config.auth = {
            username: 'omnicasa',
            password: CustomerInfo.ApiV2Token!
        };
        config.headers[APICONFIG_HEADER_LANGUAGE] = CustomerInfo.defaultLanguage!.substring(0, 2).toLocaleUpperCase();
        return config;
    },
    (error) => {
        // handle the response error
        return Promise.reject(error);
    }
);

function getAddress(reference: string): AxiosPromise<IGeoAddress> {
    return Axios({
        method: 'GET',
        url: `${config.API_URL}/client/geolocation/address?reference=${reference}`
    });
}

function getAutocomplete(search: string, cancelToken?: CancelToken): AxiosPromise<IGeoAutocomplete[]> {
    return Axios({
        method: 'GET',
        url: `${config.API_URL}/client/geolocation/autocomplete?search=${search}`,
        cancelToken: cancelToken
    });
}
