import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import RenderConfigFE from '../../../src/components/render-config-fe';
import LazyLoad from 'react-lazyload';
import ReviewSlider from '../../../src/views/review-slider';
import ContactCard from '../../../src/views/contact-card';
import { getConfigFrontEnd } from '../../../src/components/use-config-fe';
import { CustomerInfo } from '../../../build-info';
import CookiePopup from '../../../src/components/cookie-warning-popup';

const IndexPage = () => {
    const configSliderReview = getConfigFrontEnd({ name: 'SliderReview' });

    return (
        <Layout>
            <CookiePopup />

            <SEO title="Home" />

            <section className="section section-content">
                <div className="bg padding marginBottom backgroundImage">
                    <RenderConfigFE name="BackgroundImage" type="image" />
                </div>
                <ContactCard />
            </section>

            {!!configSliderReview && CustomerInfo.storySuccessSlider === 'small' && (
                <LazyLoad height={400} offset={50}>
                    <section className="section section-review">
                        <div className="review container-fluid">
                            <ReviewSlider />
                        </div>
                    </section>
                </LazyLoad>
            )}

            <LazyLoad height={400} offset={100}>
                <section className="section section-article">
                    <div className="logo-image">
                        <RenderConfigFE name="LogoImageFooter" type="image" />
                    </div>
                    <div className="container-fluid">
                        <RenderConfigFE className="title" name="FooterTitle" type="html" />
                        <RenderConfigFE name="FooterContent2" type="html" />
                    </div>
                </section>
            </LazyLoad>
        </Layout>
    );
};

export default IndexPage;
