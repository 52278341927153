import React, { useState, useEffect, useRef } from 'react';
import { geolocationService } from '../_services/geolocaltion.service';
import useDebounce from '../components/use-debounce';
import { IGeoAutocomplete } from 'src/_model/generic.model';
import Spinner from '../components/spinner';
import useOutsideClick from '../components/use-click-outside';
import { getConfigFrontEnd } from '../components/use-config-fe';
import axios from '../_services/axios.config';
import { CancelTokenSource } from 'axios';
import { translate, InjectedI18nProps, InjectedTranslateProps } from 'react-i18next';
import { TRANSLATION_DEFAULT } from '../../i18n';
import classnames from 'classnames';

const ReeValidate = require('ree-validate');

export interface IGeolocationAutocompleteProps extends InjectedI18nProps, InjectedTranslateProps {
    onSelect: (item: IGeoAutocomplete) => void;
}

let source: CancelTokenSource;
const GeolocationAutocomplete = (props: IGeolocationAutocompleteProps) => {
    const configSearchInput = getConfigFrontEnd({ name: 'SearchInput' });

    const [item, setItem] = useState<IGeoAutocomplete>({ description: '', reference: '' });
    const [query, setQuery] = useState<string>('');
    const [results, setResults] = useState<IGeoAutocomplete[]>([]);
    const [isSearching, setIsSearching] = useState(false);
    const [isShowResult, setIsShowResult] = useState(false);
    const isCancelled = React.useRef(false);
    let refCard = useRef<HTMLDivElement>(null);

    const debouncedQuery = useDebounce(query, 500);

    const validator = new ReeValidate.Validator({
        description: 'required'
    });

    const { errors } = validator;

    const validateAndSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const valid = validator.validateAll(item);
        if (valid) {
            props.onSelect(item);
        }
    };

    useOutsideClick(refCard, () => {
        setIsShowResult(false);
    });

    useEffect(
        () => {
            // Make sure we have a value (user has entered something in input)
            if (debouncedQuery) {
                if (!!source) {
                    source.cancel('Request duplicate');
                }

                source = axios.CancelToken.source();
                // Set isSearching state
                setIsSearching(true);

                geolocationService.getAutocomplete(query, source.token).then((results) => {
                    // Set back to false since request finished
                    setIsSearching(false);
                    // Set results state
                    setResults(results.data);
                });
            } else {
                setResults([]);
            }
            return () => {
                isCancelled.current = true;
                if (!!source) {
                    source.cancel('Component unmount');
                }
            };
        },
        // This is the useEffect input array
        // Our useEffect function will only execute if this value changes ...
        // ... and thanks to our hook it will only change if the original ...
        // value (searchTerm) hasn't changed for more than 500ms.
        [debouncedQuery]
    );

    const handleUpdateQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
        setResults([]);
        setQuery(event.target.value);
        setIsShowResult(true);
        setIsSearching(true);
    };

    const handleSelect = (item: IGeoAutocomplete) => {
        setQuery(item.description);
        setResults([]);
        setIsShowResult(false);
        setItem(item);
    };

    return (
        <form id="search-location-form" onSubmit={validateAndSubmit}>
            <div className="autocomp_wrapper">
                <div className="input-group">
                    <div className={classnames('form-line', { error: errors.has('firstname') })}>
                        <label>
                            <input
                                className="input-search-geolocation"
                                required
                                placeholder={configSearchInput}
                                value={query}
                                onChange={handleUpdateQuery}
                            />
                        </label>
                        {errors.has('description') && (
                            <label id="name-error" className="error" htmlFor="description">
                                {errors.first('description')}
                            </label>
                        )}
                    </div>
                    <div>
                        <button
                            className="btn-confirm-localtion btn-custom"
                            type="submit"
                            aria-label="search-geolocation">
                            {props.t('NEXT')}
                        </button>
                    </div>
                </div>
                {isShowResult === true && (
                    <div className="card card-search" ref={refCard}>
                        {isSearching && <Spinner />}
                        <ul>
                            {results.map((item: IGeoAutocomplete, index: number) => (
                                <li className="item" key={index} onClick={() => handleSelect(item)}>
                                    {item.description}
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </form>
    );
};

export default translate(TRANSLATION_DEFAULT)(GeolocationAutocomplete);
