import React from 'react';
import '../css/spinner.css';

const Spinner = () => {
    return (
        <div className="loadingio-spinner-ripple-lihzeeh7q">
            <div className="ldio-25nywnlg97l">
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default Spinner;
