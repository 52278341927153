export const LANGUAGE_ZONE = {
    nl: ''
};

export const DOMAIN_CONTACT_APPOINTMENT = 'web-contact.omnicasaweb.com';

export const DOMAIN_OMNICASA_ICON = 'https://objectstorage.omnicasa.com/data.share/omnicasa.resources/system-icons/';

export const MAPBOX_ACCESS_TOKEN =
    'pk.eyJ1IjoibGV0dW5nIiwiYSI6ImNrZzZjYjJuMTA0dGIzMHF6bm9meGlqcWUifQ.LG-E5XHp0_nDl04MrXv3Ow';

export const UNIT_CONSTANTS = {
    CURRENCY: 1,
    METRE: 2,
    SQUARE_METRE: 3,
    CUBIC_METRE: 4,
    LITRE: 5,
    PERCENT: 6,
    PICOLITER: 7,
    ENERGY: 8, // kWh/m2/j
    KILOGRAM: 9,
    NUMBER: 10
};

export const DEFAULT_NUMBER_DECIMAL = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 4
};

export const LOCALSTORAGE_LANG = 'lang';
export const LOCALSTORAGE_AUTH = 'auth';

export const APICONFIG_HEADER_AUTHORIZATION = 'Authorization';
export const APICONFIG_HEADER_LANGUAGE = 'Accept-Language';
export const APICONFIG_HEADER_SESSIONID = 'X-App-SessionId';
export const APICONFIG_HEADER_BASE_AIRTABLE = 'X-Airtable-Base';
export const APICONFIG_LANGUAGE_ID = 'LanguageID';
